import './index.css';
import Routess from './routes/Routess';

function App() {
  return (
    <>
    <Routess/>
    </>
  
  );
}

export default App; 
