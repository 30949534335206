import React from 'react'; 
import Customssss from '../Components/core/Buttons/Custom';


function HomePage() {
  return (
   <>

   <div className='bg-bgColor h-auto pb-28  space-x-6 '>
   <Customssss />
   
   </div>
 
   </>
  )
}

export default HomePage